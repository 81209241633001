import React, { useState, useEffect } from 'react';
// import PropTypes from 'prop-types';
/* eslint-disable */
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory } from 'react-router-dom';
import './style.css';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import Close from '@material-ui/icons/Close';
import Menu from '@material-ui/icons/Menu';
import Home from '@material-ui/icons/Home';
import Web from '@material-ui/icons/Web';
import api from '../../services/api';
import Icon from '@material-ui/core/Icon';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
// import Link from '@material-ui/core/Link';

const useStyles = makeStyles((theme) => ({
    toolbar: {
        borderBottom: `1px solid ${theme.palette.divider}`
    },
    toolbarTitle: {
        flex: 1
    },
    toolbarSecondary: {
        justifyContent: 'space-between',
        overflowX: 'auto'
    },
    toolbarLink: {
        padding: theme.spacing(1),
        flexShrink: 0
    }
}));

export default function Header(props) {
    const classes = useStyles();
    let history = useHistory();
    const [listaMenu, setListaMenu] = useState([]);
    const [openLOA, setOpenLOA] = useState(false);
    const [openLDO, setOpenLDO] = useState(false);
    const [highContrast, setHighContrast] = useState(false);

    // const { sections, title } = props;
    const [state, setState] = useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
    const authenticated = localStorage.getItem('@tokenTransparencia');

    useEffect(() => {
      const authenticated = localStorage.getItem('@tokenTransparencia');
      if(authenticated){
        menu()
      }
    }, []);

    const toggleContrast = () => {
      setHighContrast(!highContrast);
      document.body.classList.toggle("high-contrast");
    };
    
    const handleClickOpenLOA = () => {
      setOpenLOA(true);
    };
    const handleClickOpenLDO = () => {
      setOpenLDO(true);
    };
    const handleClose = () => {
      setOpenLOA(false);
      setOpenLDO(false);
    };

    const toggleDrawer = (anchor, open) => (event) => {
      if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
        return;
      }
  
      setState({ ...state, [anchor]: open });
    };

    const list = (anchor) => (
      <div
        // className={clsx(classes.list, {
        //   [classes.fullList]: anchor === 'top' || anchor === 'bottom',
        // })}
        style={{minWidth: 200}}
        role="presentation"
        onClick={toggleDrawer(anchor, false)}
        onKeyDown={toggleDrawer(anchor, false)}
      >
        <ListItemLink href={'https://pinheiro.ma.gov.br/'}>
            <ListItemIcon>
              <Web />
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Voltar ao site'} />
        </ListItemLink>
        <ListItemLink href={'/'}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Página principal'} />
        </ListItemLink>
        {listaMenu.map((item, key) => (
          <ListItemLink key={key} href={item.link}>
              <ListItemIcon>
                <Icon className={'fa fa-' + item.icon} style={{ width: 40 }} />
              </ListItemIcon>
              <ListItemText style={{ color: 'green' }} primary={item.nome} />
          </ListItemLink>
        ))}
        <Divider />
        {/* <ListItemLink href={'/perguntas-respostas'}>
            <ListItemIcon>
              <Icon className={'fa fa-question-circle'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Perguntas e respostas'} />
        </ListItemLink> */}
        {/* <ListItemLink href={'http://pinheiro.ma.gov.br/organograma/'}>
            <ListItemIcon>
              <Icon className={'fa fa-grip-vertical'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Estrutura do Governo'} />
        </ListItemLink>
        <Divider /> */}
        {/* <ListItemLink onClick={handleClickOpenLOA}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'LOA'} />
        </ListItemLink>
        <ListItemLink onClick={handleClickOpenLDO}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'LDO'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/PPA_PLANO_PLURIANUAL.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'PPA'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/Lei-Organica-de-Pinheiro.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Lei Orgânica'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/Lei-de-Responsabilidade-Fiscal.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Lei de Responsábilidade Fiscal'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/Lei-da-Transpar%C3%AAncia.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Lei da Transparência'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/L12527.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Lei de Acesso à Informação'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/LEI_MUNICIPAL_DE_MEIO_AMBIENTE.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'Lei municipal do meio ambiente'} />
        </ListItemLink>
        <ListItemLink href={'http://pinheiro.ma.gov.br/leis/PGRS-COMPLETO.pdf'}>
            <ListItemIcon>
              <Icon className={'fa fa-balance-scale'} style={{ width: 40 }} />  
            </ListItemIcon>
            <ListItemText style={{ color: 'green' }} primary={'PGIRS'} />
        </ListItemLink> */}
        <Divider />
        
      </div>
    );

    async function menu(){
      var token = localStorage.getItem('@tokenTransparencia');
      var config = {
        headers: {Authorization: 'bearer ' + token},
      };
      try {
        const {data : result} = await api.get('menu', config);
        console.log("Result menu", result)
        // setData(result)
        setListaMenu(result)
      } catch (error) {
        console.log("Erro menu", error, error.response);
      }
    }


    function ListItemLink(props) {
      return <ListItem button component="a" {...props} />;
    }
  
    function rota(rot){
      history.push(rot)
    }

    function sair() {
      localStorage.removeItem('@tokenTransparencia'); // remover do localStorage
      history.push('/');
    }


    return (
        <React.Fragment>
            <Container className="header-topo" maxWidth="xl">
              <div className="logo">
                  <img
                      src="https://pinheiro.ma.gov.br/wp-content/uploads/2025/01/logo-pinheiro-1.png"
                      alt="Logo"
                  />
              </div>
              {authenticated && (
                <div 
                  className="loginUsuario"
                  onClick={() => { history.push('/dashboard/')}}
                >
                  Minha Conta
                </div>
              )}
              {authenticated && (
                <div 
                  className="sairUsuario"
                  onClick={() => { sair()}}
                >
                  Sair
                </div>
              )}
            </Container>
            <div className="header-topo-secundary">
              <Container maxWidth="lg">
                <Button onClick={toggleDrawer('left', true)}>
                  <Menu style={{ color: '#ffff' }} />
                </Button>
                <Button href='https://pinheiro.ma.gov.br/'>
                  <Web style={{ color: '#ffff' }} />
                </Button>
                <Button onClick={() => {rota('/')}}>
                  <Home style={{ color: '#ffff' }} />
                </Button>
              </Container>
            </div>
            <Drawer style={{background: 'rgb(0 255 55 / 15%)'}} anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
              <div className="fechaDrawer">
              <Button onClick={toggleDrawer('left', false)}>
                  <Close style={{ color: 'green' }} />
                </Button>
              </div>
              {list('left')}
            </Drawer>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openLDO}>
              <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
                LOA por ano
              </MuiDialogTitle>
              <MuiDialogContent dividers>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <a href="http://pinheiro.ma.gov.br/leis/LOA_2018.pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                    2018
                  </a>
                  <a href="http://pinheiro.ma.gov.br/wp-content/uploads/2021/02/LOA-LEI-MUNICIPAL-No-2.796-2020..pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                    2021
                  </a>
                </div>
              </MuiDialogContent>
              <MuiDialogActions>
                <Button autoFocus onClick={handleClose} color="secudary">
                  Sair
                </Button>
              </MuiDialogActions>
            </Dialog>
            <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openLDO}>
              <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
                LDO por ano
              </MuiDialogTitle>
              <MuiDialogContent dividers>
                <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
                  <a href="http://pinheiro.ma.gov.br/leis/LDO_2018.pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                    2018
                  </a>
                  <a href="http://pinheiro.ma.gov.br/wp-content/uploads/2021/02/Lei-Municipal-2.783.pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                    2021
                  </a>
                </div>
              </MuiDialogContent>
              <MuiDialogActions>
                <Button autoFocus onClick={handleClose} color="secudary">
                  Sair
                </Button>
              </MuiDialogActions>
            </Dialog>
            <div
              style={{
                position: 'fixed',
                marginTop: '40vh',
                right: 0,
                zIndex: 9999
              }}
            >
              <button style={{padding: 10}} onClick={toggleContrast}>
                {highContrast ? <Icon className={'fa fa-adjust'} style={{ width: 40 }} /> : <Icon className={'fa fa-adjust'} style={{ width: 40 }} />}
              </button>
            </div>
        </React.Fragment>
    );
}

// Header.propTypes = {
//     sections: PropTypes.array,
//     title: PropTypes.string
// };
