import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
/* eslint-disable */
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import Paper from '@material-ui/core/Paper';
import Info from '@material-ui/icons/Info';
import Hearing from '@material-ui/icons/Hearing';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import GavelIcon from '@material-ui/icons/Gavel';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import AccountBalanceIcon from '@material-ui/icons/AccountBalance';
// import CardMedia from '@material-ui/core/CardMedia';
// import Hidden from '@material-ui/core/Hidden';

const useStyles = makeStyles({
    card: {
        display: 'flex',
        height: 250,
        position: 'relative',
        '--color-1': '#0ea759',
        '--color-2': '#016130',
        background: `
          linear-gradient(
            170deg,
            var(--color-1),
            var(--color-2) 80%
          )
        `,
        '&:hover' :{
          cursor: 'pointer',
          background: 'red',
          '--color-1': '#f50057',
          '--color-2': '#610022',
          background: `
            linear-gradient(
              170deg,
              var(--color-1),
              var(--color-2) 80%
            )
          `,
          '& svg' :{
            color: '#610022'
          }
        },
        '& svg' :{
          color: '#016130'
        }
    },
    cardDetails: {
        flex: 1,
        position: 'absolute',
        bottom: 0,

        '& h2':{
          color: '#fff',
          fontWeight: 'bold'
        },
        '& p':{
          color: '#fff',
        },
    },
    
    icon: {
        flex: 1,
        position: 'absolute',
        right: 0,
        top: 0,
        fontSize: 100,
        // color: '#00391c'
    },

});

export default function FeaturedPost(props) {
    const classes = useStyles();
    const [openLOA, setOpenLOA] = useState(false);
    const [openLDO, setOpenLDO] = useState(false);
    const { post } = props;

    useEffect(() => {
      //
    }, []);

    const handleClickOpenLOA = () => {
      setOpenLOA(true);
    };
    const handleClickOpenLDO = () => {
      setOpenLDO(true);
    };
    const handleClose = () => {
      setOpenLOA(false);
      setOpenLDO(false);
    };

    function ListItemLink(props) {
      return <ListItem button component="a" {...props} />;
    }

    return (
      <>
        <Grid item xs={12} md={12} >
          <Paper>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, background: '#0048ab'}}>
              <GavelIcon style={{fontSize: 30, color: 'white', marginRight: 10}} />
              <span style={{fontSize: 19, textTransform: 'uppercase', fontWeight: 'bold', color: 'white'}}>LRF</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: 10}}>
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/relatorio-de-gestao-fiscal-rreo/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'RREO - Relatório de Gestão Fiscal'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/relatorio-de-gestao-fiscal/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'RGF - Relatório de Gestão Fiscal'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/balanco-orcamentario/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'Balanço Orçamentário'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/relatorios-circunstanciados/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'Relatórios Circunstanciados'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/lei-orcamentaria-anual-loa/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'LOA - Lei Orçamentaria Anual'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/lei-de-diretrizes-orcamentarias-ldo/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'LDO - Lei de Diretrizes Orçamentaria'} />
              </ListItemLink>
              {/* <ListItemLink target="_blank" href={'http://pinheiro.ma.gov.br/leis/PPA_PLANO_PLURIANUAL.pdf'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'PPA'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'http://pinheiro.ma.gov.br/leis/PPA_Revisão-lei2678_16.pdf'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'PPA/Revisão'} />
              </ListItemLink> */}
              <ListItemLink target="_blank" href={'https://pinheiro.ma.gov.br/ppa-2022-a-2025/'}>
                <ListItemIcon style={{minHeight: 0}}>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                </ListItemIcon>
                <ListItemText primary={'PPA - Plano Plurianual'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'http://pinheiro.ma.gov.br/leis/Lei-de-Responsabilidade-Fiscal.pdf'}>
                  <ListItemIcon>
                    <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                  </ListItemIcon>
                  <ListItemText primary={'Lei de Responsabilidade Fiscal'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'http://pinheiro.ma.gov.br/leis/L12527.pdf'}>
                  <ListItemIcon>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                  </ListItemIcon>
                  <ListItemText primary={'LAI - Lei de Acesso à Informação'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'http://pinheiro.ma.gov.br/leis/LEI_MUNICIPAL_DE_MEIO_AMBIENTE.pdf'}>
                  <ListItemIcon>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                  </ListItemIcon>
                  <ListItemText primary={'Lei Municipal do Meio Ambiente'} />
              </ListItemLink>
              <ListItemLink target="_blank" href={'http://pinheiro.ma.gov.br/leis/PGRS-COMPLETO.pdf'}>
                  <ListItemIcon>
                  <ArrowForwardIosIcon style={{fontSize: 21, color: 'green'}}/>  
                  </ListItemIcon>
                  <ListItemText primary={'PGIRS'} />
              </ListItemLink>
            </div>
          </Paper>
        </Grid>
        <Grid item xs={12} md={12} >
          <Paper>
            <div style={{display: 'flex', alignItems: 'center', padding: 10, background: '#0048ab'}}>
              <AccountBalanceIcon style={{fontSize: 30, color: 'white', marginRight: 10}} />
              <span style={{fontSize: 19, textTransform: 'uppercase', fontWeight: 'bold', color: 'white'}}>Prefeitura</span>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: 10}}>
              <div><strong>Endereço</strong></div>
              <div>
              Prefeitura Municipal de Pinheiro, Praça José Sarney - Av. Getúlio Vargas, Pinheiro
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: 10}}>
              <div><strong>Telefones</strong></div>
              <div>
              (98) 988123937
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: 10}}>
              <div><strong>Atendimento</strong></div>
              <div>
              8h às 14h
              </div>
            </div>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start', padding: 10}}>
              <ListItemLink style={{background: '#0048ab', color: '#fff', textAlign: 'center'}} target="_blank" href={'https://pinheiro.ma.gov.br/fale-conosco/'}>
                  <ListItemText primary={'Fale conosco'} />
              </ListItemLink>
            </div>
          </Paper>
        </Grid>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openLOA}>
          <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
            LOA por ano
          </MuiDialogTitle>
          <MuiDialogContent dividers>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <a href="http://pinheiro.ma.gov.br/leis/LOA_2018.pdf" target="_blank" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2018
              </a>
              <a href="https://pinheiro.ma.gov.br/wp-content/uploads/2022/05/Lei-Municipal-2.775-de-2019-LOA-2020.pdf" target="_blank" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2020
              </a>
              <a href="https://pinheiro.ma.gov.br/wp-content/uploads/2021/02/LOA-LEI-MUNICIPAL-No-2.796-2020..pdf" target="_blank" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2021
              </a>
              <a href="https://pinheiro.ma.gov.br/loa-2022/" target="_blank" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2022
              </a>
              <a href="https://pinheiro.ma.gov.br/loa-2023/" target="_blank" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2023
              </a>
            </div>
          </MuiDialogContent>
          <MuiDialogActions>
            <Button autoFocus onClick={handleClose} color="secudary">
              Sair
            </Button>
          </MuiDialogActions>
        </Dialog>
        <Dialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={openLDO}>
          <MuiDialogTitle id="customized-dialog-title" onClose={handleClose}>
            LDO por ano
          </MuiDialogTitle>
          <MuiDialogContent dividers>
            <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}}>
              <a target="_blank"  href="http://pinheiro.ma.gov.br/leis/LDO_2018.pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2018
              </a>
              <a target="_blank"  href="https://pinheiro.ma.gov.br/'wp-content/uploads/2022/05/Lei-2.773-de-2019-LDO-2020.pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2020
              </a>
              <a target="_blank"  href="http://pinheiro.ma.gov.br/wp-content/uploads/2021/02/Lei-Municipal-2.783.pdf" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2021
              </a>
              <a target="_blank"  href="https://pinheiro.ma.gov.br/ldo-2022/" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2022
              </a>
              <a target="_blank"  href="https://pinheiro.ma.gov.br/ldo-2023/" style={{width: 150, height: 40, display: 'flex', justifyContent: 'center', alignItems: 'center', background: '#f7f7f7', marginBottom: 10}}>
                2023
              </a>
            </div>
          </MuiDialogContent>
          <MuiDialogActions>
            <Button autoFocus onClick={handleClose} color="secudary">
              Sair
            </Button>
          </MuiDialogActions>
        </Dialog>
      </>  
    );
}

FeaturedPost.propTypes = {
    post: PropTypes.object
};
